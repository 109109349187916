const info = [
  {
    title: "Délégation d'assurance",
    subTitle: "De quoi s'agit-il ? Comment procéder...",
    link: "info1",
  },
  {
    title: "Rachat d'assurance de prêt",
    subTitle: "Mode de calcul et taux annuel effectif...",
    link: "info2",
  },
  {
    title: "Assurance de prêt et âge",
    subTitle: "Age limite ? Assurance de prêt pour les Séniors...",
    link: "info3",
  },
];

export default info;
